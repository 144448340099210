import axios from "axios";

export async function callApiServiceGet () {
    let apiCallHeader = {
        'Content-Type': 'multipart/form-data; charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*'
    }
    const request = axios.get(('https://linklist.stevasdensiebert.de/api/'), {params: {}, headers: apiCallHeader})

    request
    .then(function(response) {
        console.log(response)
        return secondRequest(response);
    }).then(function(nextResponse) {
        return thirdRequest(nextResponse);
    }).then(function(finalResponse) {
        console.log('Final response: ' + finalResponse);
    }).catch(failureCallback);

    return request;

}

function secondRequest(response) {
    console.log('secondRequest', response)
}

function thirdRequest(nextResponse) {
    console.log('thirdRequest', nextResponse)
}

function failureCallback() {
    console.log('failureCallback', 'FAIL')
}

export default {
    callApiServiceGet
}